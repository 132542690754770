'use client'

import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'

import { sortAlphabetically } from '@/utils/sortAlphabetically'

interface DropdownMenuProps {
  slugs: string[]
}

const NavigationMenu: React.FC<DropdownMenuProps> = ({ slugs }) => {
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement | null>(null)

  const toggleMenu = () => {
    setIsOpen((prev) => !prev)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <nav aria-label="Main Navigation" className="relative" ref={menuRef}>
      <button
        onClick={toggleMenu}
        aria-expanded={isOpen}
        aria-controls="dropdown-menu"
        aria-label={isOpen ? 'Close menu' : 'Open menu'}
        className="text-white focus:outline-none bg-transparent h-10 w-10"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-7 w-7 text-white"
          fill="none"
          viewBox="0 0 22 22"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16m-7 6h7"
          />
        </svg>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed left-0 right-0 top-12 w-full bg-black bg-opacity-80 text-white shadow-lg z-50 overflow-hidden"
            role="menu"
            id="dropdown-menu"
          >
            <ul className="py-2 flex flex-col items-end justify-end">
              {sortAlphabetically(slugs).map((slug) => (
                <li
                  key={slug}
                  className="px-4 py-2 transition font-extralight uppercase text-md tracking-widest"
                  role="none"
                >
                  <a
                    href={`/${slug}`}
                    className="block text-white hover:text-gray-300 transition rounded"
                    role="menuitem"
                  >
                    {slug.charAt(0).toUpperCase() + slug.slice(1)}
                  </a>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  )
}

export default NavigationMenu
