import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.14_@babel+core@7.25.7_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.14_@babel+core@7.25.7_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Mate\",\"arguments\":[{\"weight\":\"400\",\"subsets\":[\"latin\"],\"variable\":\"--font-mate\",\"display\":\"swap\"}],\"variableName\":\"mate\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/.pnpm/next@14.2.14_@babel+core@7.25.7_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Sarabun\",\"arguments\":[{\"weight\":\"200\",\"subsets\":[\"latin\"],\"variable\":\"--font-sarabun\",\"display\":\"swap\"}],\"variableName\":\"sarabun\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/components/NavigationMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/src/components/ScrollToTopButton.tsx");
