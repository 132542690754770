'use client'

import React, { useEffect, useState } from 'react'

const ScrollToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisibility = () => {
    if (typeof window !== 'undefined' && window.scrollY > 300) {
      // Use scrollY instead of pageYOffset
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', toggleVisibility)
      return () => {
        window.removeEventListener('scroll', toggleVisibility)
      }
    }
  }, [])

  return (
    <button
      onClick={scrollToTop}
      className={`fixed bottom-5 right-5 bg-black bg-opacity-50 text-white w-12 h-12 flex items-center justify-center rounded-full shadow-lg transition-opacity duration-300 hover:bg-opacity-100 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
      aria-label="Scroll to top"
      tabIndex={0}
      style={{ transition: 'opacity 0.5s' }}
    >
      <span className="text-4xl transform">&#10514;</span> {/* Up arrow icon */}
    </button>
  )
}

export default ScrollToTopButton
